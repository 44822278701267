import { FlexDiv, FlexDivRowCentered } from 'components/layout/flex'
import { MOBILE_FOOTER_HEIGHT } from 'constants/ui'
import styled, { css } from 'styled-components'

export const linkCSS = css<{ underline?: boolean; hoverUnderline?: boolean }>`
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
	cursor: pointer;
	font-weight: 600;
	&:hover {
		text-decoration: ${(props) => (props.hoverUnderline ? 'underline' : 'none')};
	}
`

export const ExternalLink = styled.a.attrs<{ underline?: boolean; hoverUnderline?: boolean }>({
	target: '_blank',
	rel: 'noopener noreferrer',
})`
	${linkCSS};
`

export const resetButtonCSS = css`
	border: none;
	background: none;
	outline: none;
	cursor: pointer;
	padding: 0;
`

const absoluteCenteredCSS = css`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`

export const AbsoluteCenteredDiv = styled.div`
	${absoluteCenteredCSS};
`

export const PageContent = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	${(props) => props.theme.animations.show};
	background-color: ${(props) =>
		props.theme.colors.selectedTheme.newTheme.containers.dark.background};
	}
`

export const FixedFooterMixin = `
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 0;
`

export const MobileScreenContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	padding-bottom: ${MOBILE_FOOTER_HEIGHT};
`

export const FullHeightContainer = styled(FlexDiv)`
	justify-content: space-between;
	width: 100%;
	overflow: auto;
	flex-grow: 1;
	gap: 10px;
`

export const MainContent = styled(FlexDiv)`
	flex-grow: 1;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1280px;
`

export const LeftSideContent = styled.div`
	background-color: transparent;
	height: auto;
`

export const FOOTER_HEIGHT = 32

export const BalanceContainer = styled(FlexDivRowCentered)`
	margin-top: 12px;
	margin-bottom: 8px;
	p {
		margin: 0;
	}
`

export const BalanceText = styled.p<{ $gold?: boolean }>`
	color: ${(props) =>
		props.$gold ? props.theme.colors.selectedTheme.yellow : props.theme.colors.selectedTheme.gray};
	span {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}
`
