import type KwentaSDK from '@kwenta/sdk'
import { DEFAULT_PRICE_IMPACT_DELTA_PERCENT, SL_TP_MAX_SIZE, ZERO_WEI } from '@kwenta/sdk/constants'
import {
	FuturesMarginType,
	type NetworkId,
	OrderTypeEnum,
	type PerpsMarketV2,
	PerpsProvider,
	type PerpsV2Position,
	PositionSide,
	type SLTPOrderInputs,
	type SmartMarginOrderInputs,
	type SnxV2NetworkIds,
	TransactionStatus,
	type WriteContractParameters,
} from '@kwenta/sdk/types'
import { calculateDesiredFillPrice, simulatedRequestToTxRequest } from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type {
	ConditionOrderTableItem,
	ConditionOrderTableItemIsolated,
	FuturesPosition,
} from 'types/futures'
import type { Address } from 'viem'

import { notifyError } from 'components/ErrorNotifier'

import type { SLTPInputType } from 'sections/futures/Trade/SLTPInputField'
import { monitorAndAwaitTransaction, monitorFollowingTransaction } from 'state/app/helpers'
import {
	handleTransactionError,
	setOpenModal,
	setShowConditionalOrderModal,
	setShowEditPositionModal,
	setTransaction,
} from 'state/app/reducer'
import { fetchBalancesAndAllowances } from 'state/balances/actions'
import { ZERO_STATE_TRADE_INPUTS } from 'state/constants'
import { serializeWeiObject } from 'state/helpers'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { selectSelectedEpoch } from 'state/staking/selectors'
import type { AppDispatch, AppThunk } from 'state/store'
import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { type MarketFeesConfig, computeDelayedOrderFee } from 'utils/costCalculations'
import { orderPriceInvalidLabel } from 'utils/futures'
import logError from 'utils/logError'
import { refetchWithComparator } from 'utils/queries'

import {
	editClosePositionPrice,
	editClosePositionSizeDelta,
	editIsolatedMarginTradeSize,
	fetchAllOpenOrders,
	fetchMarginTransfers,
	fetchOpenConditionalOrders,
	stageTradePreview,
} from '../actions'
import { fetchAccountMarginInfo, submitFuturesTransaction } from '../common/actions'
import {
	selectAccountData,
	selectClosePositionOrderInputs,
	selectEditCOModalInputs,
	selectEditPositionInputs,
	selectLeverageSide,
	selectMarketIndexPrice,
	selectMarkets,
	selectSkewAdjustedPriceInfo,
	selectSlTpModalInputs,
	selectSnxPerpsV2Network,
	selectTradeOrderType,
	selectTradePanelInputs,
} from '../common/selectors'
import type { IsolatedTradePreviewParams } from '../common/types'
import {
	clearEditPositionInputs,
	clearTradePreview,
	handlePreviewError,
	setCancellingConditionalOrder,
	setClosePositionPrice,
	setClosePositionSizeDelta,
	setConditionalOrderPriceInvalidLabel,
	setEditConditonalOrderModalMargin,
	setEditConditonalOrderModalPrice,
	setEditConditonalOrderModalSize,
	setEditPositionInputs,
	setLeverageInput,
	setMarginDelta,
	setOrderPriceInvalidLabel,
	setSLTPModalStopLossAmount,
	setSLTPModalStopLossPrice,
	setSLTPModalTakeProfitAmount,
	setSLTPModalTakeProfitPrice,
	setSlippageInput,
	setTotalTradeFees,
	setTradeInputs,
	setTradePanelOrderPrice,
	setTradeStopLoss,
	setTradeTakeProfit,
	updateAccountData,
} from '../reducer'
import {
	selectAccountContext,
	selectClosePositionPreview,
	selectEditPositionModalInfo,
	selectEditPositionPreview,
} from '../selectors'
import type { ExecuteDelayedOrderInputs } from '../types'

import type { TransactionOrder } from 'state/app/types'
import type { Transaction } from 'types/accountAbstraction'
import {
	selectIsolatedMarginDelayedOrders,
	selectSnxV2Account,
	selectSnxV2AccountContext,
} from './selectors'

// TODO: Move to futures/common

const refetchIsolatedMarginPosition = createAsyncThunk<
	void,
	{ position: FuturesPosition<string> },
	ThunkConfig
>(
	'futures/refetchIsolatedMarginPosition',
	async ({ position }, { dispatch, getState, extra: { sdk } }) => {
		const accountData = selectAccountData(getState())
		const market = position.market as PerpsMarketV2<string>
		if (!accountData) throw new Error('No wallet connected')
		if (!position || accountData.marginType === FuturesMarginType.CROSS_MARGIN)
			throw new Error('Market or position not found')

		const result = await refetchWithComparator(
			() =>
				sdk.snxPerpsV2.getPositions({
					account: accountData.account,
					futuresMarkets: [
						{
							asset: market.asset,
							address: market.marketAddress,
						},
					],
					chainId: accountData.network as SnxV2NetworkIds,
				}),
			position?.details.margin.remainingMargin as any,
			(existing, next) => {
				return existing === (next[0]?.remainingMargin?.toString() ?? '0')
			}
		)

		if (result.data[0]) {
			const serialized = serializeWeiObject(
				result.data[0] as PerpsV2Position
			) as PerpsV2Position<string>

			const existingPositions = (accountData?.positions ?? []) as PerpsV2Position<string>[]
			const index = existingPositions.findIndex((p) => p.asset === serialized.asset)
			existingPositions[index] = serialized

			dispatch(
				updateAccountData({
					wallet: accountData.account,
					data: {
						provider: accountData.provider,
						positions: existingPositions,
						network: accountData.network,
						account: accountData.account,
					},
				})
			)
		}
	}
)

export const clearSmartMarginTradeInputs = createAsyncThunk<void, void, ThunkConfig>(
	'futures/clearSmartMarginTradeInputs',
	async (_, { dispatch }) => {
		dispatch(setLeverageInput(''))
		dispatch(clearTradePreview())
		dispatch(setTradeInputs(ZERO_STATE_TRADE_INPUTS))
		dispatch(clearEditPositionInputs())
		dispatch(setMarginDelta(''))
		dispatch(setTradeStopLoss(''))
		dispatch(setTradeTakeProfit(''))
	}
)

export const editIsolatedMarginConditionalOrder =
	(
		order: ConditionOrderTableItem,
		newSize: string,
		newPrice: string,
		marginDelta: string
	): AppThunk =>
	(dispatch, getState) => {
		const markets = selectMarkets(getState())
		const { accountId } = selectAccountContext(getState())
		const indexPrice = selectMarketIndexPrice(getState())
		const marketPrice = selectSkewAdjustedPriceInfo(getState())

		const market = markets.find((m) => m.asset === order.asset)
		if (!market || market.marginType === FuturesMarginType.CROSS_MARGIN)
			throw new Error('Missing market data')
		if (!accountId) throw new Error('No account selected')

		try {
			dispatch(
				stageTradePreview({
					accountId,
					provider: market.provider,
					market,
					orderPrice: wei(newPrice || 0),
					indexPrice: wei(indexPrice),
					sizeDelta: wei(newSize || 0),
					action: 'edit',
					marginDelta: wei(marginDelta || 0),
					isConditional: true,
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editIsolatedMarginConditionalOrderPrice =
	(order: ConditionOrderTableItem, newPrice: string): AppThunk =>
	(dispatch, getState) => {
		const { size, margin } = selectEditCOModalInputs(getState())

		const priceInfo = selectOffchainPricesInfo(getState())
		const price = priceInfo[order.asset]?.price

		if (!price) throw new Error('Missing price data')

		dispatch(setEditConditonalOrderModalPrice(newPrice))
		const invalidLabel = orderPriceInvalidLabel(
			newPrice,
			order.tradeDirection,
			price,
			order.orderType
		)

		dispatch(setConditionalOrderPriceInvalidLabel(invalidLabel))
		dispatch(editIsolatedMarginConditionalOrder(order, size || '0', newPrice, margin))
	}

export const editSmartMarginConditionalOrderSize =
	(order: ConditionOrderTableItem, size: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setEditConditonalOrderModalSize(size))
		const { orderPrice, margin } = selectEditCOModalInputs(getState())

		dispatch(editIsolatedMarginConditionalOrder(order, size, orderPrice.price || '0', margin))
	}

export const editSmartMarginConditionalOrderMargin =
	(order: ConditionOrderTableItem, margin: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setEditConditonalOrderModalMargin(margin))
		const { orderPrice, size } = selectEditCOModalInputs(getState())

		dispatch(editIsolatedMarginConditionalOrder(order, size, orderPrice.price || '0', margin))
	}

export const editSmartMarginTradeSlippage =
	(slippage: string): AppThunk =>
	(dispatch, getState) => {
		const inputs = selectTradePanelInputs(getState())
		dispatch(setSlippageInput(slippage))
		// Recalc the trade
		dispatch(editIsolatedMarginTradeSize(inputs.susdSize, 'usd'))
	}

export const editCloseIsolatedPositionSizeDelta =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setClosePositionSizeDelta(nativeSizeDelta))
		const { marketPrice, market, indexPrice } = selectEditPositionModalInfo(getState())
		const { accountId } = selectAccountContext(getState())

		if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')

		if (nativeSizeDelta === '' || !nativeSizeDelta) {
			dispatch(clearTradePreview())
			return
		}
		const { price, orderType } = selectClosePositionOrderInputs(getState())

		try {
			const isConditional = orderType !== OrderTypeEnum.MARKET

			const previewParams: IsolatedTradePreviewParams = {
				provider: market.provider,
				accountId,
				market,
				marketPrice: wei(marketPrice),
				sizeDelta: wei(nativeSizeDelta),
				orderPrice: isConditional && price?.value ? wei(price.value) : undefined,
				indexPrice: wei(indexPrice ?? 0),
				marginDelta: ZERO_WEI,
				action: 'close',
				isConditional: isConditional,
			}
			dispatch(stageTradePreview(previewParams))
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: PerpsProvider.SNX_V2_OP,
				})
			)
		}
	}

export const editIsolatedMarginPositionSize =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		const accountId = selectAccountData(getState())?.account
		const { marketPrice, position, indexPrice } = selectEditPositionModalInfo(getState())
		const { orderPrice } = selectEditPositionInputs(getState())

		if (!position?.market || position?.market.provider !== PerpsProvider.SNX_V2_OP)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')
		try {
			dispatch(
				stageTradePreview({
					provider: position.market.provider,
					accountId,
					indexPrice: wei(indexPrice ?? 0),
					market: position.market,
					orderPrice: wei(orderPrice || marketPrice),
					marginDelta: ZERO_WEI,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'edit',
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: PerpsProvider.SNX_V2_OP,
				})
			)
		}
	}

export const editIsolatedMarginPositionOrderPrice =
	(orderPrice: string): AppThunk =>
	(dispatch, getState) => {
		const accountId = selectAccountData(getState())?.account
		const { marketPrice, position, indexPrice } = selectEditPositionModalInfo(getState())
		const { nativeSizeDelta } = selectEditPositionInputs(getState())

		if (!position?.market || position?.market.provider !== PerpsProvider.SNX_V2_OP)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')
		try {
			dispatch(
				stageTradePreview({
					provider: position.market.provider,
					accountId,
					indexPrice: wei(indexPrice ?? 0),
					market: position.market,
					orderPrice: wei(orderPrice || marketPrice),
					marginDelta: ZERO_WEI,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'edit',
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: PerpsProvider.SNX_V2_OP,
				})
			)
		}
	}

export const editCloseIsolatedPositionPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const { nativeSizeDelta, orderType } = selectClosePositionOrderInputs(getState())
		const { position, marketPrice, market, indexPrice } = selectEditPositionModalInfo(getState())
		const { accountId } = selectAccountContext(getState())

		const closeTradeSide =
			position?.details.side === PositionSide.SHORT ? PositionSide.LONG : PositionSide.SHORT
		const invalidLabel = orderPriceInvalidLabel(
			price || '0',
			closeTradeSide,
			marketPrice,
			orderType
		)

		dispatch(setClosePositionPrice({ value: price, invalidLabel }))

		if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')

		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market,
					orderPrice: price ? wei(price) : undefined,
					indexPrice: wei(indexPrice ?? 0),
					marketPrice: wei(marketPrice),
					marginDelta: ZERO_WEI,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'close',
					isConditional: orderType !== OrderTypeEnum.MARKET,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: market.provider,
				})
			)
		}
	}

export const editSmartMarginPositionMargin =
	(marginDelta: string): AppThunk =>
	(dispatch, getState) => {
		const { marketPrice, market, accountId } = selectEditPositionModalInfo(getState())

		if (market?.provider !== PerpsProvider.SNX_V2_OP) throw new Error('Invalid market provider')
		if (!accountId) throw new Error('No account selected')

		dispatch(
			setEditPositionInputs({
				marginDelta: marginDelta,
				nativeSizeDelta: '',
				orderPrice: '',
			})
		)
		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market,
					orderPrice: undefined,
					indexPrice: wei(marketPrice),
					marginDelta: wei(marginDelta || 0),
					sizeDelta: ZERO_WEI,
					action: 'edit',
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editSmartMarginTradeOrderPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const rate = selectSkewAdjustedPriceInfo(getState())
		if (!rate) throw new Error('No skew adjusted price')
		const orderType = selectTradeOrderType(getState())
		const side = selectLeverageSide(getState())
		const inputs = selectTradePanelInputs(getState())
		dispatch(setTradePanelOrderPrice(price))
		const invalidLabel = orderPriceInvalidLabel(price, side, rate.price, orderType)
		dispatch(setOrderPriceInvalidLabel(invalidLabel))
		if (!invalidLabel && wei(inputs.susdSize || 0).gt(0)) {
			// Recalc the trade
			dispatch(editIsolatedMarginTradeSize(inputs.susdSize || '0', 'usd'))
		}
	}

export const calculateSnxV2TradeFees = (params: {
	sizeDelta: Wei
	orderPrice: Wei
	market: MarketFeesConfig
}) => {
	const { delayedOrderFee: tradeFee } = computeDelayedOrderFee(
		params.market,
		params.sizeDelta.mul(params.orderPrice?.abs())
	)

	return {
		tradeFee,
	}
}

export const fetchFuturesFees = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchFuturesFees',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		for (const provider of providers) {
			switch (provider) {
				case PerpsProvider.SNX_V2_OP: {
					const { start, end } = selectSelectedEpoch(getState())
					const chainId = selectSnxPerpsV2Network(getState())

					try {
						const totalFuturesFeePaid = await sdk.kwentaToken.getFuturesFee(start, end, chainId)
						dispatch(
							setTotalTradeFees({
								provider: PerpsProvider.SNX_V2_OP,
								totalFees: totalFuturesFeePaid.toString(),
							})
						)
					} catch (err) {
						logError(err)
						throw err
					}
					break
				}
				default:
					throw new Error('Invalid provider')
			}
		}
	}
)

export const fetchFuturesFeesForAccount = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchFuturesFeesForAccount',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		let account: Address | undefined = undefined
		let network: NetworkId | undefined = undefined

		for (const provider of providers) {
			if (provider === PerpsProvider.SNX_V2_OP) {
				const { start, end } = selectSelectedEpoch(getState())
				network = selectSnxPerpsV2Network(getState())
				account = selectSnxV2Account(getState())

				if (!wallet || !account) return
				try {
					const futuresFeePaid = await sdk.kwentaToken.getFuturesFeeForAccount(
						wallet,
						start,
						end,
						network
					)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								network,
								account,
								provider: PerpsProvider.SNX_V2_OP,
								totalFeesPaid: futuresFeePaid.toString(),
							},
						})
					)
				} catch (err) {
					logError(err)
					throw err
				}
			}
		}
	}
)

// Contract Mutations

export const depositSmartMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/depositSmartMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { accountId, network } = selectSnxV2AccountContext(state)

		if (!accountId) {
			notifyError('No account connected')
			return
		}
		await submitSMTransferTransaction(dispatch, sdk, {
			type: 'deposit_smart_margin',
			account: accountId,
			amount,
			chainId: network,
		})
	}
)

export const withdrawIsolatedMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawIsolatedMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network, provider, wallet } = selectAccountContext(getState())

		if (!accountId || !wallet) {
			notifyError('Account not connected')
			return
		}

		if (provider === PerpsProvider.SNX_V2_OP) {
			await submitSMTransferTransaction(dispatch, sdk, {
				type: 'withdraw_isolated_margin',
				account: accountId,
				amount,
				chainId: network as SnxV2NetworkIds,
			})
		}
	}
)

export const approveIsolatedMargin = createAsyncThunk<void, void, ThunkConfig>(
	'futures/approveIsolatedMargin',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { accountId, network, provider, wallet } = selectAccountContext(state)

		if (!accountId || !wallet) throw new Error('No account connected')

		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_cross_margin',
					hash: null,
				})
			)

			const txHash = await sdk.snxPerpsV2.approveSmartMarginDeposit({
				address: accountId as Address,
				chainId: network as SnxV2NetworkIds,
			})
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(fetchAccountMarginInfo([provider]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitIsolatedMarginAdjustMargin = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitIsolatedMarginAdjustMargin',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()

		const { market, position } = selectEditPositionModalInfo(getState())
		const { accountId, network } = selectAccountContext(state)

		const { marginDelta } = selectEditPositionInputs(state)

		try {
			if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
				throw new Error('Market info not found')
			if (!accountId) throw new Error('No account connected found')
			if (!position) throw new Error('No position found')
			if (!marginDelta || marginDelta === '') throw new Error('No margin amount set')

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
				})
			)

			let request: Transaction | undefined = undefined

			if (market.provider === PerpsProvider.SNX_V2_OP) {
				const { request: snxRequest } = await sdk.snxPerpsV2.modifySmartMarginMarketMargin({
					address: accountId as Address,
					market: market.marketAddress,
					marginDelta: wei(marginDelta),
					isOwner: false,
					chainId: network as SnxV2NetworkIds,
				})
				request = simulatedRequestToTxRequest(snxRequest)
			}

			if (!request) throw new Error('Failed to generate contract request')

			const callback = () => {
				dispatch(clearSmartMarginTradeInputs())
				dispatch(setOpenModal(null))
			}

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: callback,
				})
			)

			dispatch(setShowEditPositionModal(null))
			dispatch(refetchIsolatedMarginPosition({ position }))
			dispatch(fetchBalancesAndAllowances())
			dispatch(clearSmartMarginTradeInputs())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitIsolatedMarginAdjustPositionSize = createAsyncThunk<void, boolean, ThunkConfig>(
	'futures/submitIsolatedMarginAdjustPositionSize',
	async (overridePriceProtection, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network } = selectAccountContext(state)

		const preview = selectEditPositionPreview(state)
		const { nativeSizeDelta } = selectEditPositionInputs(state)
		const openDelayedOrders = selectIsolatedMarginDelayedOrders(state)

		try {
			if (!market?.provider || market.marginType === FuturesMarginType.CROSS_MARGIN)
				throw new Error('Missing or invalid market info')
			if (!accountId) throw new Error('No account connected found')
			if (!nativeSizeDelta || nativeSizeDelta === '') throw new Error('No margin amount set')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const order: TransactionOrder<string> = {
				marketAsset: market.asset,
				newSize: wei(preview.newSize).abs().toString(),
				sizeDelta: wei(preview.sizeDelta).abs().toString(),
				type: OrderTypeEnum.MARKET,
				side: preview.side,
				price: preview.fillPrice,
			}

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
					order,
				})
			)

			let existingSize = wei(position?.details.size)
			existingSize =
				position?.details.side === PositionSide.SHORT ? existingSize.neg() : existingSize
			const isClosing = existingSize.add(nativeSizeDelta).eq(0)

			const staleOrder = openDelayedOrders.find((o) => o.isStale && o.market.asset === market.asset)

			let request: Transaction | undefined = undefined

			if (market.provider === PerpsProvider.SNX_V2_OP) {
				const { request: snxRequest } = await sdk.snxPerpsV2.modifySmartMarginPositionSize({
					address: accountId as Address,
					market,
					sizeDelta: wei(nativeSizeDelta),
					desiredFillPrice: wei(preview.desiredFillPrice),
					cancelPendingReduceOrders: isClosing,
					cancelExpiredDelayedOrders: !!staleOrder,
					chainId: network as SnxV2NetworkIds,
				})
				request = simulatedRequestToTxRequest(snxRequest)
			}
			if (!request) throw new Error('Failed to generate contract request')

			const callback = () => {
				dispatch(clearSmartMarginTradeInputs())
				dispatch(setOpenModal(null))
			}

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: callback,
				})
			)
			dispatch(setShowEditPositionModal(null))
			dispatch(fetchAccountMarginInfo([market.provider]))
			dispatch(fetchBalancesAndAllowances())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitIsolatedMarginReducePositionOrder = createAsyncThunk<void, boolean, ThunkConfig>(
	'futures/submitIsolatedMarginReducePositionOrder',
	async (overridePriceProtection, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network, wallet } = selectAccountContext(state)
		const { nativeSizeDelta, orderType, price } = selectClosePositionOrderInputs(state)
		const preview = selectClosePositionPreview(state)
		const openDelayedOrders = selectIsolatedMarginDelayedOrders(state)

		try {
			if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
				throw new Error('Market info not found')
			if (!wallet) throw new Error('No wallet connected')
			if (!accountId) throw new Error('No account connected found')
			if (!nativeSizeDelta || nativeSizeDelta === '') throw new Error('No margin amount set')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const originSide = position?.details.side ?? preview.side
			const otherSide = originSide === PositionSide.LONG ? PositionSide.SHORT : PositionSide.LONG

			const order: TransactionOrder<string> = {
				marketAsset: market.asset,
				newSize: wei(preview.newSize).abs().toString(),
				sizeDelta: wei(preview.sizeDelta).abs().toString(),
				type: orderType,
				side: wei(preview.sizeDelta).gt(0) ? originSide : otherSide,
				price: preview.fillPrice,
			}

			const isClosing = wei(nativeSizeDelta)
				.abs()
				.eq(wei(position?.details.size ?? 0).abs())

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
					order,
				})
			)

			const orderInputs: SmartMarginOrderInputs = {
				sizeDelta: wei(nativeSizeDelta),
				marginDelta: wei(0),
				desiredFillPrice: wei(preview.desiredFillPrice),
			}

			if (orderType !== OrderTypeEnum.MARKET) {
				orderInputs.conditionalOrderInputs = {
					orderType,
					price: wei(price?.value || '0'),
					reduceOnly: true,
				}
			}

			const staleOrder = openDelayedOrders.find((o) => o.isStale && o.market.asset === market.asset)

			let request: Transaction | undefined = undefined

			if (market.provider === PerpsProvider.SNX_V2_OP) {
				const { request: v2Request } =
					isClosing && orderType === OrderTypeEnum.MARKET
						? await sdk.snxPerpsV2.closeSmartMarginPosition({
								market,
								address: accountId as Address,
								desiredFillPrice: wei(preview.desiredFillPrice),
								chainId: network as SnxV2NetworkIds,
								cancelExpiredDelayedOrders: !!staleOrder,
							})
						: await sdk.snxPerpsV2.submitSmartMarginOrder({
								market,
								walletAddress: wallet,
								smAddress: accountId as Address,
								order: orderInputs,
								chainId: network as SnxV2NetworkIds,
								options: { cancelExpiredDelayedOrders: !!staleOrder },
							})

				request = simulatedRequestToTxRequest(v2Request)
			}

			if (!request) throw new Error('Failed to generate contract request')

			const callback = () => {
				dispatch(clearSmartMarginTradeInputs())
				dispatch(setOpenModal(null))
				if (market) {
					dispatch(editClosePositionSizeDelta(''))
					dispatch(editClosePositionPrice(''))
				}
				dispatch(setShowEditPositionModal(null))
			}

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: callback,
				})
			)

			dispatch(fetchBalancesAndAllowances())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const updateIsolatedConditionalOrder = createAsyncThunk<
	void,
	{
		order: ConditionOrderTableItemIsolated
		sizeDelta: Wei
		targetPrice: Wei
		marginDelta: Wei
		desiredFillPrice: Wei
		marketAddress: Address
	},
	ThunkConfig
>(
	'futures/updateIsolatedConditionalOrder',
	async (
		{ order, sizeDelta, targetPrice, marginDelta, desiredFillPrice },
		{ getState, dispatch, extra: { sdk } }
	) => {
		const state = getState()
		const { accountId, wallet, network } = selectAccountContext(state)

		try {
			if (!accountId || !wallet) throw new Error('No account connected')
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
				})
			)

			const tx = await sdk.snxPerpsV2.updateConditionalOrder({
				account: accountId as Address,
				asset: order.asset,
				orderId: order.id,
				chainId: network as SnxV2NetworkIds,
				params: {
					orderType: order.orderType,
					reduceOnly: order.reduceOnly,
					price: targetPrice,
					desiredFillPrice,
					sizeDelta,
					marginDelta,
				},
			})
			const request = simulatedRequestToTxRequest(tx.request)

			if (!request) throw new Error('Failed to generate contract request')

			dispatch(setCancellingConditionalOrder(order.id))

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: () => dispatch(setOpenModal(null)),
				})
			)

			dispatch(setCancellingConditionalOrder(undefined))
			dispatch(fetchAllOpenOrders([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			logError(err)
			dispatch(setCancellingConditionalOrder(undefined))
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		} finally {
			dispatch(setShowConditionalOrderModal(null))
			dispatch(setShowEditPositionModal(null))
		}
	}
)

export const withdrawAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV2AccountContext(getState())
		try {
			if (!accountId) throw new Error('No account connected')
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_keeper_balance',
					hash: null,
				})
			)

			const { request } = await sdk.snxPerpsV2.withdrawAccountKeeperBalance({
				address: accountId,
				amount,
				chainId: network,
			})
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(setOpenModal(null))
			dispatch(fetchAccountMarginInfo([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const depositAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/depositAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV2AccountContext(getState())

		try {
			if (!accountId) throw new Error('No account connected')

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_keeper_balance',
					hash: null,
				})
			)
			const tx = await sdk.transactions.sendTransaction(
				{
					to: accountId,
					value: amount.toBigInt(),
				},
				network
			)

			await monitorAndAwaitTransaction(network, dispatch, tx)
			dispatch(setOpenModal(null))
			dispatch(fetchAccountMarginInfo([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const executeDelayedOrder = createAsyncThunk<void, ExecuteDelayedOrderInputs, ThunkConfig>(
	'futures/executeDelayedOrder',
	async ({ marketAsset, marketAddress, originTxHash }, { getState, extra: { sdk } }) => {
		const { network, accountId } = selectSnxV2AccountContext(getState())
		if (!accountId) throw new Error('No account connected')
		const { request } = await sdk.snxPerpsV2.executeDelayedOffchainOrder({
			marketAsset,
			marketAddress: marketAddress as Address,
			account: accountId,
			chainId: network,
		})

		const txHash = await sdk.transactions.writeContract(request as WriteContractParameters, network)

		await monitorFollowingTransaction(originTxHash, txHash, network)
	}
)

// Utils

const submitSMTransferTransaction = async (
	dispatch: AppDispatch,
	sdk: KwentaSDK,
	{
		type,
		account,
		amount,
		chainId,
	}: {
		type: 'withdraw_isolated_margin' | 'deposit_smart_margin'
		account: string
		amount: Wei
		chainId: SnxV2NetworkIds
	}
) => {
	dispatch(
		setTransaction({
			chainId,
			status: TransactionStatus.AwaitingExecution,
			type: type,
			hash: null,
		})
	)

	try {
		const { request } =
			type === 'deposit_smart_margin'
				? await sdk.snxPerpsV2.depositSmartMarginAccount({
						address: account as Address,
						amount,
						chainId,
					})
				: await sdk.snxPerpsV2.withdrawSmartMarginAccount({
						address: account as Address,
						amount,
						chainId,
					})
		const txHash = await sdk.transactions.writeContract(request as WriteContractParameters, chainId)
		await monitorAndAwaitTransaction(chainId, dispatch, txHash)
		dispatch(fetchAccountMarginInfo([PerpsProvider.SNX_V2_OP]))
		dispatch(setOpenModal(null))
		dispatch(fetchBalancesAndAllowances())
		dispatch(fetchMarginTransfers([PerpsProvider.SNX_V2_OP]))
		return txHash
	} catch (err) {
		logError(err)
		dispatch(handleTransactionError({ message: err.message }))
		throw err
	}
}

export const updateStopLossAndTakeProfitIsolatedMargin = createAsyncThunk<
	void,
	{ updateStopLoss: boolean; updateTakeProfit: boolean },
	ThunkConfig
>(
	'futures/updateStopLossAndTakeProfitIsolatedMargin',
	async ({ updateStopLoss, updateTakeProfit }, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network, wallet } = selectAccountContext(state)
		const { stopLossPrice, takeProfitPrice } = selectSlTpModalInputs(state)

		try {
			if (!market || market?.marginType === FuturesMarginType.CROSS_MARGIN)
				throw new Error('Market info not found')
			if (!accountId) throw new Error('No account connected found')
			if (!wallet) throw new Error('No wallet connected')

			const maxSizeDelta =
				position?.details.side === PositionSide.LONG ? SL_TP_MAX_SIZE.neg() : SL_TP_MAX_SIZE

			const desiredSLFillPrice = calculateDesiredFillPrice(
				maxSizeDelta,
				wei(stopLossPrice || 0),
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
			)

			const desiredTPFillPrice = calculateDesiredFillPrice(
				maxSizeDelta,
				wei(takeProfitPrice || 0),
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
			)

			const params: SLTPOrderInputs = {}

			// To separate Stop Loss and Take Profit from other limit / stop orders
			// we set the size to max big num value.

			if (updateStopLoss) {
				if (Number(stopLossPrice) > 0) {
					params.stopLoss = {
						price: wei(stopLossPrice),
						desiredFillPrice: desiredSLFillPrice,
						sizeDelta: maxSizeDelta,
					}
				} else if (!stopLossPrice) {
					params.stopLoss = {
						price: wei(0),
						desiredFillPrice: wei(0),
						sizeDelta: wei(0),
						isCancelled: true,
					}
				}
			}

			if (updateTakeProfit) {
				if (Number(takeProfitPrice) > 0) {
					params.takeProfit = {
						price: wei(takeProfitPrice),
						desiredFillPrice: desiredTPFillPrice,
						sizeDelta: maxSizeDelta,
					}
				} else if (!takeProfitPrice) {
					params.takeProfit = {
						price: wei(0),
						desiredFillPrice: wei(0),
						sizeDelta: wei(0),
						isCancelled: true,
					}
				}
			}

			if (params.stopLoss || params.takeProfit) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'submit_isolated_order',
						hash: null,
					})
				)

				let request: Transaction | undefined = undefined

				if (market.provider === PerpsProvider.SNX_V2_OP) {
					const tx = await sdk.snxPerpsV2.updateStopLossAndTakeProfit({
						asset: market.asset,
						account: accountId as Address,
						params,
						chainId: network as SnxV2NetworkIds,
					})
					request = simulatedRequestToTxRequest(tx.request)
				}

				if (!request) throw new Error('Failed to generate contract request')

				const callback = () => {
					if (market) {
						dispatch(editClosePositionSizeDelta(''))
						dispatch(editClosePositionPrice(''))
					}
					dispatch(setShowEditPositionModal(null))
				}

				await dispatch(
					submitFuturesTransaction({
						request,
						onSuccess: callback,
					})
				)

				dispatch(fetchOpenConditionalOrders([market.provider]))
			}
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

/**
 * Create limit orders for stop loss and take profit
 */
export const setPartialStopLossAndTakeProfitIsolatedMargin = createAsyncThunk<
	void,
	void,
	ThunkConfig
>(
	'futures/setPartialStopLossAndTakeProfitIsolatedMargin',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network, wallet } = selectAccountContext(state)
		const { stopLossPrice, takeProfitPrice, stopLossAmount, takeProfitAmount } =
			selectSlTpModalInputs(state)

		try {
			if (!market || market?.marginType === FuturesMarginType.CROSS_MARGIN)
				throw new Error('Market info not found')
			if (!accountId) throw new Error('No account connected found')
			if (!wallet) throw new Error('No wallet connected')

			const isLong = position?.details.side === PositionSide.LONG

			const maxSizeDelta = isLong ? SL_TP_MAX_SIZE.neg() : SL_TP_MAX_SIZE

			const desiredSLFillPrice = calculateDesiredFillPrice(
				maxSizeDelta,
				wei(stopLossPrice || 0),
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
			)

			const desiredTPFillPrice = calculateDesiredFillPrice(
				maxSizeDelta,
				wei(takeProfitPrice || 0),
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
			)

			const orderInputs: SmartMarginOrderInputs = {
				sizeDelta: wei(0),
				marginDelta: wei(0),
				desiredFillPrice: wei(position.details.price.entryPrice),
			}

			// To separate Stop Loss and Take Profit from other limit / stop orders
			// we set the size to max big num value.
			if (wei(stopLossPrice || 0).gt(0) && wei(stopLossAmount || 0).gt(0)) {
				orderInputs.stopLoss = {
					sizeDelta: isLong ? wei(stopLossAmount).neg() : wei(stopLossAmount),
					price: wei(stopLossPrice),
					desiredFillPrice: desiredSLFillPrice,
				}
			}

			if (wei(takeProfitPrice || 0).gt(0) && wei(takeProfitAmount || 0).gt(0)) {
				orderInputs.takeProfit = {
					sizeDelta: isLong ? wei(takeProfitAmount).neg() : wei(takeProfitAmount),
					price: wei(takeProfitPrice),
					desiredFillPrice: desiredTPFillPrice,
				}
			}

			if (orderInputs.takeProfit || orderInputs.stopLoss) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'submit_isolated_order',
						hash: null,
					})
				)

				const { request } = await sdk.snxPerpsV2.submitSmartMarginOrder({
					market: { marketAddress: market.marketAddress, asset: market.asset },
					walletAddress: wallet,
					smAddress: accountId as Address,
					order: orderInputs,
					isOwner: false,
					chainId: network as SnxV2NetworkIds,
				})

				const callback = () => {
					dispatch(setEditPositionInputs({ nativeSizeDelta: '', marginDelta: '', orderPrice: '' }))
					dispatch(setShowEditPositionModal(null))
				}
				await dispatch(
					submitFuturesTransaction({
						request,
						onSuccess: callback,
					})
				)
				dispatch(fetchOpenConditionalOrders([market.provider]))
			}
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const changeSLTPPrice =
	(price: string, type: SLTPInputType): AppThunk =>
	(dispatch) => {
		dispatch(
			type === 'take-profit' ? setSLTPModalTakeProfitPrice(price) : setSLTPModalStopLossPrice(price)
		)
	}

export const changeSLTPAmount =
	(amount: string, type: SLTPInputType): AppThunk =>
	(dispatch) => {
		dispatch(
			type === 'take-profit'
				? setSLTPModalTakeProfitAmount(amount)
				: setSLTPModalStopLossAmount(amount)
		)
	}
