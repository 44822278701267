import { EVENT_ACTION, EVENT_CATEGORY } from 'constants/matomoEvents'
import { selectProviderNetwork } from 'state/futures/common/selectors'
import { useAppSelector } from 'state/hooks'
import { type EventAction, type EventName, bucketTradeSize, trackEvent } from 'utils/analytics'

export const useAnalytics = () => {
	const chainId = useAppSelector(selectProviderNetwork)

	const trackExchangeEvent = (
		action: EventAction,
		name: EventName,
		value?: string,
		customVariables?: [string, string | number][]
	) => {
		trackEvent(EVENT_CATEGORY.EXCHANGE, action, name, value, [
			['Chain', chainId],
			...(customVariables || []),
		])
	}

	return {
		trackTransferDepositFlow: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.TRANSFER_DEPOSIT, name, value)
		},

		trackTransferWithdrawFlow: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.TRANSFER_WITHDRAW, name, value)
		},

		trackOneClickTrading: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.ONE_CLICK_TRADING, name, value)
		},

		trackAccountCreation: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.ACCOUNT_CREATION, name, value)
		},

		trackManageAccount: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.MANAGE, name, value)
		},

		trackAcknowledgeTerms: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.ACKNOWLEDGE_TERMS, name, value)
		},

		trackTrade: (name: EventName, asset: string, notionalSize?: number) => {
			const sizeTag = notionalSize ? bucketTradeSize(Number(notionalSize)) : undefined
			trackExchangeEvent(EVENT_ACTION.TRADE, name, asset, sizeTag ? [['size', sizeTag]] : undefined)
		},

		trackLogin: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.LOGIN, name, value)
		},

		trackDepositFlow: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.DEPOSIT, name, value)
		},

		trackWithdrawFlow: (name: EventName, value?: string) => {
			trackExchangeEvent(EVENT_ACTION.WITHDRAW, name, value)
		},
	}
}
