import type { EVENT_ACTION, EVENT_CATEGORY, EVENT_NAME } from '../constants/matomoEvents'

export type EventCategory = (typeof EVENT_CATEGORY)[keyof typeof EVENT_CATEGORY]
export type EventAction = (typeof EVENT_ACTION)[keyof typeof EVENT_ACTION]
export type EventName = (typeof EVENT_NAME)[keyof typeof EVENT_NAME]

/**
 * Track a user interaction event in Matomo
 * @param category The event category (e.g., 'exchange')
 * @param action The event action (e.g., 'deposit')
 * @param name The event name for more specific tracking (e.g., 'click_deposit')
 * @param value Optional value associated with the event
 * @param chainId Optional chain ID to include as custom variable
 */
export const trackEvent = (
	category: EventCategory,
	action: EventAction,
	name: EventName,
	value?: string,
	customVariables?: [string, string | number][]
) => {
	if (window?._paq) {
		if (customVariables) {
			customVariables.forEach(([key, value]) => {
				window._paq!.push(['setCustomDimension', key, value])
			})
		}
		const eventData = ['trackEvent', category, action]
		if (name) eventData.push(name)
		if (value !== undefined) eventData.push(value)
		window._paq.push(eventData)
	}
}

export const bucketTradeSize = (size: number) => {
	if (size < 100) return 'xsmall'
	if (size < 1000) return 'small'
	if (size < 10000) return 'medium'
	if (size < 100000) return 'large'
	if (size < 1000000) return 'xlarge'
	return 'xxlarge'
}
