import type { WalletClientType } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'

import type { ThunkConfig } from 'state/types'

import { disconnect, setWalletAddress } from './reducer'
import type { SetWalletPayload } from './types'

export const resetWalletAddress = createAsyncThunk<void, SetWalletPayload, ThunkConfig>(
	'wallet/resetWalletAddress',
	async ({ address, selectedType }, { dispatch }) => {
		dispatch(setWalletAddress({ address, selectedType }))
		Sentry.setUser({ id: address })
	}
)

export const setWalletClient = createAsyncThunk<
	void,
	WalletClientType | null | undefined,
	ThunkConfig
>('wallet/setWalletClient', async (walletClient, { dispatch, extra: { sdk } }) => {
	if (walletClient) {
		await sdk.setWalletClient(walletClient)
	} else {
		dispatch(disconnect())
		dispatch({ type: 'balances/clearBalances' })
	}
})
