import { APP_INITIAL_STATE } from './app/reducer'
import { BALANCES_INITIAL_STATE } from './balances/reducer'
import { FUTURES_INITIAL_STATE } from './futures/reducer'
import { HOME_INITIAL_STATE } from './home/reducer'
import { PREFERENCES_INITIAL_STATE } from './preferences/reducer'
import { PRICES_INITIAL_STATE } from './prices/reducer'
import { REFERRALS_INITIAL_STATE } from './referrals/reducer'
import { STAKING_INITIAL_STATE } from './staking/reducer'
import { STATS_INITIAL_STATE } from './stats/reducer'
import { WALLET_INITIAL_STATE } from './wallet/reducer'

const migrations = {
	7: (state: any) => {
		return {
			...state,
			app: APP_INITIAL_STATE,
			balances: BALANCES_INITIAL_STATE,
			futures: FUTURES_INITIAL_STATE,
			home: HOME_INITIAL_STATE,
			preferences: PREFERENCES_INITIAL_STATE,
			prices: PRICES_INITIAL_STATE,
			staking: STAKING_INITIAL_STATE,
			stats: STATS_INITIAL_STATE,
			wallet: WALLET_INITIAL_STATE,
		}
	},
	63: (state: any) => {
		return {
			...state,
			balances: BALANCES_INITIAL_STATE,
			futures: FUTURES_INITIAL_STATE,
			home: HOME_INITIAL_STATE,
			preferences: PREFERENCES_INITIAL_STATE,
			prices: PRICES_INITIAL_STATE,
			staking: STAKING_INITIAL_STATE,
			stats: STATS_INITIAL_STATE,
			wallet: WALLET_INITIAL_STATE,
			referral: REFERRALS_INITIAL_STATE,
		}
	},
	72: (state: any) => {
		return {
			...state,
			preferenes: {
				...state.preferences,
				currentTheme: 'dark',
			},
		}
	},
	117: (state: any) => {
		return {
			...state,
			balances: BALANCES_INITIAL_STATE,
			home: HOME_INITIAL_STATE,
			preferenes: PREFERENCES_INITIAL_STATE,
			prices: PRICES_INITIAL_STATE,
			staking: STAKING_INITIAL_STATE,
			stats: STATS_INITIAL_STATE,
			wallet: WALLET_INITIAL_STATE,
			referral: REFERRALS_INITIAL_STATE,
			futures: FUTURES_INITIAL_STATE,
		}
	},
}

export const STORE_VERSION = Object.keys(migrations).sort((a, b) => Number(b) - Number(a))[0]

export default migrations
