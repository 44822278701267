import {
	type BiconomySmartAccountV2,
	type BuildUserOpOptions,
	Bundler,
	DEFAULT_MULTICHAIN_MODULE,
	DEFAULT_SESSION_KEY_MANAGER_MODULE,
	PaymasterMode,
	type PaymasterUserOperationDto,
	createSmartAccountClient,
} from '@biconomy/account'
import {
	type MultiChainValidationModule,
	type SessionKeyManagerModule,
	createMultiChainValidationModule,
	createSessionKeyManagerModule,
} from '@biconomy/modules'

import { simulatedRequestToTxRequest } from '@kwenta/sdk/utils'
import { StorageKeys } from 'services/storage/storageKeys'
import {
	AbstractAccountAbstraction,
	type SendUserOpParameters,
	type SendUserOperationsRequest,
	type SessionInfo,
	type Transaction,
} from 'types/accountAbstraction'
import {
	http,
	type Address,
	type Hex,
	type PublicClient,
	type WalletClient,
	createWalletClient,
	encodeAbiParameters,
	encodeFunctionData,
	isHex,
	parseAbiItem,
	parseAbiParameters,
} from 'viem'
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts'

import { BICONOMY_VALIDATION_MODULE_ADDRESS } from 'constants/address'
import { ALCHEMY_NETWORK_LOOKUP } from 'state/sdk'
import { type DateInterval, ONE_SECOND_IN_MS, convertIntervalToSeconds } from 'utils/dates'
import { getRandomInteger } from 'utils/numbers'

export class AccountAbstraction extends AbstractAccountAbstraction {
	private sdk?: BiconomySmartAccountV2

	private validationModule?: MultiChainValidationModule
	private sessionKeyManagerModule?: SessionKeyManagerModule

	private readonly MULTICHAIN_MODULE_ADDRESS = DEFAULT_MULTICHAIN_MODULE

	public accountAddress?: Address
	protected _chainId?: number
	private sessionKeyModuleAddress?: Address
	private validationModuleAddress?: Address

	public get chainId() {
		return this._chainId
	}

	private set chainId(chainId: number | undefined) {
		this._chainId = chainId
	}

	public async init(client: WalletClient, publicClient: PublicClient) {
		const validationModule = await this.initValidationModule(client)

		const chainId = publicClient.chain!.id

		this.chainId = chainId

		this.validationModuleAddress = BICONOMY_VALIDATION_MODULE_ADDRESS[chainId]
		this.sessionKeyModuleAddress = DEFAULT_SESSION_KEY_MANAGER_MODULE

		const rpcUrl = `https://${ALCHEMY_NETWORK_LOOKUP[chainId]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`

		this.sdk = await createSmartAccountClient({
			bundler: new Bundler({
				bundlerUrl: rpcUrl,
				chainId,
				userOpReceiptMaxDurationIntervals: {
					[chainId]: ONE_SECOND_IN_MS * 60,
				},
				rpcUrl,
			}),
			paymasterUrl: rpcUrl,
			paymasterPolicy: process.env.NEXT_PUBLIC_ALCHEMY_POLICY_ID!,
			signer: client,
			defaultValidationModule: validationModule,
			activeValidationModule: validationModule,
			rpcUrl,
			chainId,
		})

		this.accountAddress = (await this.sdk.getAccountAddress()) as Address

		if (this.sessionKeyManagerModule) {
			await this.getOrCreateSessionKeyManagerModule(true) // update session module with new key
		}

		this.notifyAll()
	}

	public disconnect() {
		this.sdk = undefined
		this.accountAddress = undefined
		this.chainId = undefined

		this.notifyAll()
	}

	private async initValidationModule(signer: WalletClient) {
		this.validationModule = await createMultiChainValidationModule({
			signer,
			moduleAddress: this.MULTICHAIN_MODULE_ADDRESS,
		})
		return this.validationModule
	}

	private async checkSessionKeyModuleEnabled() {
		if (!this.sdk || !this.sessionKeyModuleAddress) {
			throw new Error('SDK not initialized')
		}

		try {
			const sessionKeyModuleEnabled = await this.sdk.isModuleEnabled(this.sessionKeyModuleAddress)

			return sessionKeyModuleEnabled
		} catch (_e) {
			return false
		}
	}

	private async getOrCreateSessionKeyManagerModule(create = false) {
		if (this.sessionKeyModuleAddress === undefined || this.accountAddress === undefined) {
			throw new Error('SDK not initialized')
		}

		if (!this.sessionKeyManagerModule || create) {
			this.sessionKeyManagerModule = await createSessionKeyManagerModule({
				moduleAddress: this.sessionKeyModuleAddress,
				smartAccountAddress: this.accountAddress,
				chainId: this.chainId!,
			})
		}

		return this.sessionKeyManagerModule
	}

	public async createSession(interval: DateInterval | number, address: Address) {
		if (
			!this.sdk ||
			!this.accountAddress ||
			!this.validationModuleAddress ||
			!this.sessionKeyModuleAddress
		) {
			throw new Error('SDK not initialized')
		}

		try {
			const savedKey = this.storage.get(StorageKeys.SESSION_KEY)
			const key = (savedKey as Hex) ?? generatePrivateKey()
			const account = privateKeyToAccount(key)

			if (!savedKey) {
				this.storage.save(StorageKeys.SESSION_KEY, key)
			}

			this.removeLocalSessions()

			const sessionModule = await this.getOrCreateSessionKeyManagerModule(true)

			const sessionKeyData = encodeAbiParameters(parseAbiParameters('address x, address y'), [
				account.address,
				address,
			])

			const now = Math.floor(Date.now() / 1000)
			const secInterval =
				typeof interval === 'number' ? interval : convertIntervalToSeconds(interval)
			const getFutureTimestamp = now + secInterval

			const sessionData = await sessionModule.createSessionData([
				{
					validUntil: getFutureTimestamp,
					validAfter: now,
					sessionValidationModule: this.validationModuleAddress,
					sessionPublicKey: account.address,
					sessionKeyData,
				},
			])

			const enableTx = {
				to: this.sessionKeyModuleAddress,
				data: sessionData.data as Hex,
			}

			const isSessionKeyModuleEnabled = await this.checkSessionKeyModuleEnabled()

			const transactions: Transaction[] = []

			if (!isSessionKeyModuleEnabled) {
				const initTx = (await this.sdk.getEnableModuleData(
					this.sessionKeyModuleAddress
				)) as Transaction

				transactions.push(initTx)
			}

			transactions.push(enableTx)

			const options: BuildUserOpOptions = {}
			options.paymasterServiceData = this.preparePaymasterData()

			const { wait } = await this.sendTransactions({
				readyTxs: transactions,
				options,
			})

			const { success } = await wait()

			if (!success) {
				throw new Error('Session creation failed')
			}

			this.sdk = this.sdk.setActiveValidationModule(sessionModule)

			const session = await this.getSessionInfo()

			return session
		} catch (e) {
			this.removeLocalSessions()
			this.getOrCreateSessionKeyManagerModule()

			throw new Error(e)
		}
	}

	public async getSessionInfo(): Promise<SessionInfo | undefined> {
		if (this.sdk === undefined || this.accountAddress === undefined) {
			throw new Error('SDK not initialized')
		}

		try {
			const privateKey = this.storage.get(StorageKeys.SESSION_KEY)

			if (!privateKey) {
				throw new Error('Session key not found')
			}

			const account = privateKeyToAccount(privateKey as Hex)

			const sessionModule = await this.getOrCreateSessionKeyManagerModule()

			const session = await sessionModule.sessionStorageClient.getSessionData({
				sessionValidationModule: this.validationModuleAddress,
				sessionPublicKey: account.address,
			})

			return session
		} catch (_e) {
			return undefined
		}
	}

	public removeLocalSessions() {
		if (!this.accountAddress) {
			return
		}

		// TODO: Remove this when Biconomy will fix their bug
		localStorage.setItem(
			`${this.accountAddress.toLowerCase()}_sessions_${this.chainId}`,
			JSON.stringify({ merkleRoot: '', leafNodes: [] })
		)
		this.sessionKeyManagerModule = undefined
		this.sdk = this.sdk?.setActiveValidationModule(this.validationModule!)
	}

	public async closeAllSessions() {
		const { userOp, options } = await this.prepareCloseAllSessions()
		const { wait } = await this.sendUserOperation({ userOp, options })

		const { success } = await wait()

		if (success === 'false') {
			throw new Error('Failed to close session')
		}

		this.removeLocalSessions()

		return this.getSessionInfo()
	}

	// We create a prepare method for estimate avg tx cost
	private async prepareCloseAllSessions() {
		if (!this.sdk || !this.accountAddress || !this.sessionKeyModuleAddress) {
			throw new Error('SDK not initialized')
		}

		// We sent empty merkleTree for validation module (restrict all previous sessions)
		const transactions = [
			{
				to: this.sessionKeyModuleAddress,
				data: encodeFunctionData({
					abi: [parseAbiItem('function setMerkleRoot(bytes32 _merkleRoot)')],
					args: ['0x0000000000000000000000000000000000000000000000000000000000000000'],
					functionName: 'setMerkleRoot',
				}),
			},
		]

		return this.prepareUserOp({ readyTxs: transactions, options: {} })
	}

	public async sendTransactions(params: SendUserOperationsRequest) {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		try {
			const { userOp, options } = await this.prepareUserOp(params)
			const res = await this.sendUserOperation({ userOp, options })

			return res
		} catch (e) {
			switch (e.message) {
				case "AA21 didn't pay prefund":
					throw new Error(
						'One-Click Trading balance is low. Please, fund your wallet and try again!'
					)
				case 'AA33 reverted: BTPM: account does not have enough token balance from Bundler':
					throw new Error(
						'One-Click Trading balance is low. Please, fund your wallet and try again!'
					)
				case 'AA23 reverted: SessionNotApproved':
					this.removeLocalSessions()
					throw new Error('Session invalid, open a new session to continue using One-Click Trading')
				default:
					throw new Error(e.message)
			}
		}
	}

	public async prepareUserOp({ readyTxs, simulateTxs, options }: SendUserOperationsRequest) {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		if (!(readyTxs?.length || simulateTxs?.length)) {
			throw new Error('No transactions to send')
		}

		const formattedTxs = readyTxs ?? []

		if (simulateTxs) {
			formattedTxs.push(...simulateTxs.map(simulatedRequestToTxRequest))
		}

		const opts = options ?? (await this.prepareSessionOptions())
		if (options) {
			this.sdk = this.sdk.setActiveValidationModule(this.validationModule!)
		} else {
			this.sdk = this.sdk.setActiveValidationModule(this.sessionKeyManagerModule!)
		}

		if (!opts.paymasterServiceData) {
			opts.paymasterServiceData = this.preparePaymasterData()
		}

		opts.nonceOptions = {
			nonceKey: getRandomInteger(),
		}

		const userOp = await this.sdk.buildUserOp(formattedTxs, {
			...opts,
		})

		return {
			userOp,
			options: opts.params,
		}
	}

	public async sendUserOperation({ userOp, options }: SendUserOpParameters) {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		const res = await this.sdk.sendUserOp(userOp, options)

		return res
	}

	private async prepareSessionOptions(): Promise<BuildUserOpOptions> {
		if (!this.sdk) {
			throw new Error('SDK not initialized')
		}

		const privateKey = this.storage.get(StorageKeys.SESSION_KEY)

		if (!privateKey) {
			throw new Error('Session key not found')
		}

		if (!isHex(privateKey)) {
			throw new Error('Session key is not a valid hex')
		}

		const account = privateKeyToAccount(privateKey)

		const sessionSigner = createWalletClient({
			account,
			chain: this.sdk.rpcProvider.chain,
			transport: http(),
		})

		const params = {
			sessionSigner,
			sessionValidationModule: this.validationModuleAddress,
		}

		return {
			params,
		}
	}

	public preparePaymasterData(): PaymasterUserOperationDto | undefined {
		return {
			mode: PaymasterMode.SPONSORED,
		}
	}
}
