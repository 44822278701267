// Takes a comparitor which should return a bool condition to
// signal to continue retrying, comparing prev and new query result

import logError from './logError'

type RefetchStatus = 'timeout' | 'complete'
type RefetchResult<T> = { data: T | null; status: RefetchStatus }

export const refetchWithComparator = async <T>(
	query: () => Promise<T>,
	existingResult: T,
	comparator: (previous: T, current: T) => boolean,
	interval = 1000,
	max = 25
): Promise<RefetchResult<T>> => {
	return new Promise((res) => {
		let count = 1

		const refetch = async (existingResult: T) => {
			const timeout = setTimeout(async () => {
				if (count > max) {
					clearTimeout(timeout)
					logError(new Error('refetch timeout'))
					res({ data: null, status: 'timeout' })
				} else {
					const next = await query()
					count += 1
					if (!comparator(existingResult, next)) {
						clearTimeout(timeout)
						res({ data: next, status: 'complete' })
					} else {
						refetch(existingResult)
					}
				}
			}, interval)
		}
		refetch(existingResult)
	})
}
