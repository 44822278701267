import { type FC, memo, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import BaseModal from 'components/BaseModal'
import Button from 'components/Button'
import Spacer from 'components/Spacer'
import { Body } from 'components/Text'
import { FlexDivCol } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'
import { EVENT_NAME } from 'constants/matomoEvents'
import ROUTES from 'constants/routes'
import Connector from 'containers/Connector'
import { useAnalytics } from 'hooks/useAnalytics'
import { useRouter } from 'next/router'
import { setOpenModal } from 'state/app/reducer'
import { setAcknowledgementTerms } from 'state/futures/reducer'
import {
	selectAcknowledgementTerms,
	selectHasAcceptedAcknowledgementTerms,
} from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSignerWallet } from 'state/wallet/selectors'
import { ExternalLink } from 'styles/common'
import media from 'styles/media'

type Props = {
	onDismiss(): void
}

const AcknowledgementTerms: FC<Props> = memo(({ onDismiss }) => {
	const { t } = useTranslation()
	const router = useRouter()
	const walletAddress = useAppSelector(selectSignerWallet)
	const acknowledgedAddresses = useAppSelector(selectAcknowledgementTerms)
	const hasAcceptedAcknowledgementTerms = useAppSelector(selectHasAcceptedAcknowledgementTerms)
	const dispatch = useAppDispatch()
	const { logout } = Connector.useContainer()
	const { trackAcknowledgeTerms } = useAnalytics()

	const isNotHomePage = useMemo(() => router.asPath !== ROUTES.Home.Root, [router.asPath])

	const handleDisconnect = useCallback(() => {
		trackAcknowledgeTerms(EVENT_NAME.SIGN_OUT)
		logout()
		dispatch(setOpenModal(null))
	}, [logout, dispatch, trackAcknowledgeTerms])

	const handleAccept = useCallback(() => {
		trackAcknowledgeTerms(EVENT_NAME.AGREE_AND_CONTINUE)

		if (!walletAddress) return
		dispatch(
			setAcknowledgementTerms({
				...acknowledgedAddresses,
				[walletAddress.toLowerCase()]: true,
			})
		)
	}, [acknowledgedAddresses, dispatch, walletAddress, trackAcknowledgeTerms])

	return (
		walletAddress &&
		!hasAcceptedAcknowledgementTerms &&
		isNotHomePage && (
			<StyledBaseModal
				title={t('account.acknowledge-terms.title')}
				onDismiss={onDismiss}
				showCross={false}
			>
				<Spacer height={20} />
				<Body color="secondary">
					<Trans
						i18nKey="account.acknowledge-terms.copy"
						components={[
							<LinkText key="terms" href={EXTERNAL_LINKS.Docs.TermsOfService} />,
							<LinkText key="privacy" href={EXTERNAL_LINKS.Docs.PrivacyPolicy} />,
						]}
					/>
				</Body>
				<Spacer height={30} />
				<FlexDivCol rowGap="20px">
					<TextContainer color="secondary">
						{t('account.acknowledge-terms.risk-check-box')}
					</TextContainer>
					<TextContainer color="secondary">
						{t('account.acknowledge-terms.country-check-box')}
					</TextContainer>
					<Button variant="flat" size="small" onClick={handleAccept}>
						{t('account.acknowledge-terms.agree-and-continue')}
					</Button>
					<Button variant="flat" size="small" onClick={handleDisconnect}>
						{t('account.acknowledge-terms.sign-out')}
					</Button>
				</FlexDivCol>
			</StyledBaseModal>
		)
	)
})

const LinkText = styled(ExternalLink)`
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
	font-weight: 600;
`

const TextContainer = styled(Body)`
	margin-top: -2px;
`

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		max-width: 400px;
		margin-top: 200px;
	}

	${media.lessThan('md')`
		[data-reach-dialog-content] {
			max-width: 100%;
		}
	`}
`

export default AcknowledgementTerms
