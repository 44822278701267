import { useRouter } from 'next/router'
import { memo, useCallback, useMemo } from 'react'

import UnderlineTabs from 'components/Button/UnderlineTabs'
import ROUTES from 'constants/routes'
import {
	selectMarketAsset,
	selectPerpsProvider,
	selectUserInfoCollapsed,
} from 'state/futures/common/selectors'
import { setPreferences, setSelectedTab } from 'state/futures/reducer'
import { selectActivePositionsCount, selectConditionalOrdersCount } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { providerIsCrossMargin } from 'utils/futures'

export enum FuturesTab {
	POSITION = 'position',
	CONDITIONAL_ORDERS = 'conditional_orders',
	TRANSACTION_HISTORY = 'transaction_history',
	ORDER_HISTORY = 'order_history',
	TRADES = 'trades',
	ASSETS = 'assets',
}

export const FutureTabs = Object.values(FuturesTab)

const UserInfoTabs = memo(({ activeTab }: { activeTab: FuturesTab }) => {
	const router = useRouter()
	const dispatch = useAppDispatch()

	const marketAsset = useAppSelector(selectMarketAsset)
	const perpsProvider = useAppSelector(selectPerpsProvider)

	const positionsCount = useAppSelector(selectActivePositionsCount)
	const conditionalOrdersCount = useAppSelector(selectConditionalOrdersCount)

	const userInfoCollapsed = useAppSelector(selectUserInfoCollapsed)

	const togglePanelIfCollapsed = useCallback(() => {
		if (userInfoCollapsed) {
			dispatch(setPreferences({ userInfoCollapsed: false }))
		}
	}, [dispatch, userInfoCollapsed])

	const handleTabClick = useCallback(
		(tab: FuturesTab) => {
			togglePanelIfCollapsed()
			dispatch(setSelectedTab(tab))

			switch (tab) {
				case FuturesTab.POSITION:
					router.push(ROUTES.Markets.Position(marketAsset, perpsProvider), undefined, {
						scroll: false,
						shallow: true,
					})
					break
				case FuturesTab.CONDITIONAL_ORDERS:
					router.push(ROUTES.Markets.ConditionalOrders(marketAsset, perpsProvider), undefined, {
						scroll: false,
						shallow: true,
					})
					break
				case FuturesTab.TRANSACTION_HISTORY:
					router.push(ROUTES.Markets.TransactionHistory(marketAsset, perpsProvider), undefined, {
						scroll: false,
						shallow: true,
					})
					break
				case FuturesTab.ORDER_HISTORY:
					router.push(ROUTES.Markets.OrderHistory(marketAsset, perpsProvider), undefined, {
						scroll: false,
						shallow: true,
					})
					break
				case FuturesTab.TRADES:
					router.push(ROUTES.Markets.Trades(marketAsset, perpsProvider), undefined, {
						scroll: false,
						shallow: true,
					})
					break
				case FuturesTab.ASSETS:
					router.push(ROUTES.Markets.Assets(marketAsset, perpsProvider), undefined, {
						scroll: false,
						shallow: true,
					})
					break
			}
		},
		[dispatch, togglePanelIfCollapsed, router, marketAsset, perpsProvider]
	)

	const userTabs = useMemo(
		() => [
			{
				name: FuturesTab.POSITION,
				label: 'Positions',
				badge: positionsCount,
				active: activeTab === FuturesTab.POSITION,
				onClick: () => handleTabClick(FuturesTab.POSITION),
			},
			{
				name: FuturesTab.CONDITIONAL_ORDERS,
				label: 'Orders',
				badge: conditionalOrdersCount,
				active: activeTab === FuturesTab.CONDITIONAL_ORDERS,
				onClick: () => handleTabClick(FuturesTab.CONDITIONAL_ORDERS),
			},
			{
				name: FuturesTab.TRANSACTION_HISTORY,
				label: 'Transaction History',
				active: activeTab === FuturesTab.TRANSACTION_HISTORY,
				onClick: () => handleTabClick(FuturesTab.TRANSACTION_HISTORY),
			},
			{
				name: FuturesTab.ORDER_HISTORY,
				label: 'Order History',
				active: activeTab === FuturesTab.ORDER_HISTORY,
				onClick: () => handleTabClick(FuturesTab.ORDER_HISTORY),
			},
			{
				name: FuturesTab.TRADES,
				label: 'Trades',
				badge: undefined,
				active: activeTab === FuturesTab.TRADES,
				onClick: () => handleTabClick(FuturesTab.TRADES),
			},
			{
				name: FuturesTab.ASSETS,
				label: 'Assets',
				badge: undefined,
				active: activeTab === FuturesTab.ASSETS,
				onClick: () => handleTabClick(FuturesTab.ASSETS),
			},
		],
		[positionsCount, activeTab, conditionalOrdersCount, handleTabClick]
	)

	return (
		<UnderlineTabs
			options={userTabs
				.filter((tab) =>
					providerIsCrossMargin(perpsProvider) ? true : tab.name !== FuturesTab.ASSETS
				)
				.filter((tab) => tab.name !== FuturesTab.TRANSACTION_HISTORY)}
		/>
	)
})

export default UserInfoTabs
