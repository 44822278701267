export type EventCategory = 'exchange'

export type EventAction =
	| 'acknowledge_terms'
	| '1ct'
	| 'deposit'
	| 'withdraw'
	| 'manage'
	| 'account_creation'
	| 'trade'

export type EventName =
	| 'click_agree_and_continue'
	| 'click_sign_out'
	| 'click_setup_1ct'
	| 'submit_activate_1ct'
	| 'click_sign_every_txn'
	| 'click_deposit'
	| 'click_withdraw'
	| 'submit_approve_and_deposit'
	| 'submit_deposit'
	| 'submit_withdraw'
	| 'click_manage_account'
	| 'click_create_account'
	| 'submit_create_account'
	| 'select_market'
	| 'submit_market_order'
	| 'submit_limit_order'
	| 'submit_edit_position_size'
	| 'submit_close_position'

export const EVENT_CATEGORY: Record<string, EventCategory> = {
	EXCHANGE: 'exchange',
} as const

export const EVENT_ACTION: Record<string, EventAction> = {
	ACKNOWLEDGE_TERMS: 'acknowledge_terms',
	ONE_CLICK_TRADING: '1ct',
	DEPOSIT: 'deposit',
	WITHDRAW: 'withdraw',
	MANAGE: 'manage',
	ACCOUNT_CREATION: 'account_creation',
	TRADE: 'trade',
} as const

export const EVENT_NAME: Record<string, EventName> = {
	// Acknowledge Terms events
	CLICK_AGREE_CONTINUE: 'click_agree_and_continue',
	CLICK_SIGN_OUT: 'click_sign_out',

	// 1CT events
	CLICK_SETUP_1CT: 'click_setup_1ct',
	SUBMIT_ACTIVATE_1CT: 'submit_activate_1ct',
	CLICK_SIGN_EVERY_TXN: 'click_sign_every_txn',

	// Deposit events
	CLICK_DEPOSIT: 'click_deposit',
	SUBMIT_APPROVE_DEPOSIT: 'submit_approve_and_deposit',
	SUBMIT_DEPOSIT: 'submit_deposit',

	// Withdraw events
	SUBMIT_WITHDRAW: 'submit_withdraw',
	CLICK_WITHDRAW: 'click_withdraw',

	// Manage events
	CLICK_MANAGE_ACCOUNT: 'click_manage_account',

	// Account Creation events
	CLICK_CREATE_ACCOUNT: 'click_create_account',
	SUBMIT_CREATE_ACCOUNT: 'submit_create_account',

	// Trade events
	SELECT_MARKET: 'select_market',
	SUBMIT_MARKET_ORDER: 'submit_market_order',
	SUBMIT_LIMIT_ORDER: 'submit_limit_order',
	SUBMIT_EDIT_POSITION_SIDE: 'submit_edit_position_size',
	SUBMIT_CLOSE_POSITION: 'submit_close_position',
}
